<template>
  <section id="about" class="py-16 bg-gray-100">
    <div class="container mx-auto px-4">
      <!-- About Header -->
      <div class="text-left mb-8">
        <h2 class="text-3xl font-bold text-gray-800">About</h2>
        <!-- Orange underline -->
        <div class="mt-2 h-1 bg-orange-500 w-3/4"></div>
      </div>

      <!-- About Card -->
      <div class="bg-white rounded-lg shadow-lg p-8">
        <div class="flex flex-wrap lg:flex-nowrap items-center gap-8">
          <!-- About Text -->
          <div class="w-full lg:w-2/3">
            <p class="text-lg text-gray-700 leading-relaxed">
              Welcome to my personal website. This brief about me section was generated by AI while building the site, cause I couldn't be bothered.
              
              <br /><br />
              
              This site also serves as my digital playground where I tinker with various UI components in Nuxt and experiment with different dev tools like Sentry. Why didn't I just learn COBOL? Real job security.
            </p>
          </div>

          <!-- Profile Image -->
          <div class="w-full lg:w-1/3">
            <img 
              src="/assets/img/profile-img.png" 
              alt="Steven Eubank" 
              class="rounded-lg w-full h-auto object-cover shadow-md"
            >
          </div>
        </div>
      </div>

      <!-- Grid Layout (Certifications and Permits) -->
      <div class="mt-16">
        <h3 class="text-3xl font-bold text-gray-800 mb-4">Certifications and Permits</h3>
        <div class="mt-2 h-1 bg-orange-500 w-3/4"></div>

        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mt-8">
          <div class="p-4 bg-white shadow-md rounded-lg flex items-center">
            <font-awesome-icon :icon="['fas', 'cloud']" class="text-blue-600 mr-4" style="font-size: 1.5rem;" />
            <div>
              <span class="text-sm font-bold block">AWS Solution Architect Associate</span>
              <span class="text-xs block">2020</span>
            </div>
          </div>
          <div class="p-4 bg-white shadow-md rounded-lg flex items-center">
            <font-awesome-icon :icon="['fas', 'user-tie']" class="text-blue-600 mr-4" style="font-size: 1.5rem;" />
            <div>
              <span class="text-sm font-bold block">Allianz Business Analyst Certification</span>
              <span class="text-xs block">2018</span>
            </div>
          </div>
          <div class="p-4 bg-white shadow-md rounded-lg flex items-center">
            <font-awesome-icon :icon="['fas', 'database']" class="text-blue-600 mr-4" style="font-size: 1.5rem;" />
            <div>
              <span class="text-sm font-bold block">Allianz Business System Certification Levels 1 & 2</span>
              <span class="text-xs block">2016 & 2017</span>
            </div>
          </div>
          <div class="p-4 bg-white shadow-md rounded-lg flex items-center">
            <font-awesome-icon :icon="['fas', 'comments']" class="text-blue-600 mr-4" style="font-size: 1.5rem;" />
            <div>
              <span class="text-sm font-bold block">Native English & B1 German (ÖIF)</span>
              <span class="text-xs block">2020</span>
            </div>
          </div>
          <div class="p-4 bg-white shadow-md rounded-lg flex items-center">
            <font-awesome-icon :icon="['fas', 'flag']" class="text-blue-600 mr-4" style="font-size: 1.5rem;" />
            <div>
              <span class="text-sm font-bold block">US Citizen</span>
              <span class="text-xs block">Birth</span>
            </div>
          </div>
          <div class="p-4 bg-white shadow-md rounded-lg flex items-center">
            <font-awesome-icon :icon="['fas', 'id-card']" class="text-blue-600 mr-4" style="font-size: 1.5rem;" />
            <div>
              <span class="text-sm font-bold block">Permanent Austrian Residence Permit "Daueraufenthalt – EU"</span>
              <span class="text-xs block">2019-</span>
            </div>
          </div>
          <div class="p-4 bg-white shadow-md rounded-lg flex items-center">
            <font-awesome-icon :icon="['fas', 'car']" class="text-blue-600 mr-4" style="font-size: 1.5rem;" />
            <div>
              <span class="text-sm font-bold block">EU A/AM/B Class Driving License</span>
            </div>
          </div>
          <!-- New Item: Other Misc Certs -->
          <div class="p-4 bg-white shadow-md rounded-lg flex items-center">
            <font-awesome-icon :icon="['fas', 'file-alt']" class="text-blue-600 mr-4" style="font-size: 1.5rem;" />
            <div>
              <span class="text-sm font-bold block">Other Misc Certs Corpos Made Me Do</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { defineComponent } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

// Import specific icons
import { faHouse, faUser, faFileAlt, faStream, faEnvelope, faCloud, faUserTie, faDatabase, faComments, faFlag, faIdCard, faCar, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub, faInstagram, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';

// Add all icons to the library to ensure they are available
library.add(fas, fab, faHouse, faUser, faFileAlt, faStream, faEnvelope, faCloud, faUserTie, faDatabase, faComments, faFlag, faIdCard, faCar, faChevronDown, faLinkedin, faGithub, faInstagram, faFacebook, faTwitter);

const CertificationCard = defineComponent({
  props: {
    icon: {
      type: [String, Array],
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      default: '',
    },
  },
  template: `
    <div class="p-4 bg-white shadow-md rounded-lg flex items-center">
      <font-awesome-icon :icon="icon" class="text-blue-600 mr-4" style="font-size: 1.5rem;" />
      <div>
        <span class="text-sm font-bold block">{{ title }}</span>
        <span v-if="date" class="text-xs block">{{ date }}</span>
      </div>
    </div>
  `,
  components: {
    FontAwesomeIcon,
  },
});
</script>

<style scoped>
/* Add custom styles if needed */
</style>
